import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import useDialog from 'hooks/useDialog';
import useLGPDConsents from 'hooks/useLGPDConsents';
import usePendingComments from 'hooks/usePendingComments';
import useSingleton from 'hooks/useSingleton';
import useTheme from 'hooks/useTheme';

import { dispatch } from 'lib/eventManager';
import { getFromLocalStorage, setLocalStorage } from 'lib/localStorage';
import { sendSupixEvent } from 'lib/supix';
import { getLoggedInUser } from 'lib/user';
import { browserOnly, isDenied, isDev, noop } from 'lib/utils';

import { useLocalWishlist } from './LocalWishlistProvider';

import COOKIES from 'constants/cookies';
import LOCAL_STORAGE from 'constants/localStorage';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const TOKEN_EXPIRATION_TIME = 60 * 60 * 24 * 365; // 1 year
const BROADCAST_CHANNEL_FALLBACK = {
  addEventListener: noop,
  removeEventListener: noop,
  postMessage: noop,
};

export const USER_LOGOUT_EVENT = 'user-logout';
export const USER_LOGIN_EVENT = 'user-login';

const UserContext = createContext({
  loadingLoggedInUser: true,
  LoggedInUser: null,
  isLoggedIn: null,
  login: () => {},
  logout: () => {},
  runCallbackIfLoggedIn: () => {},
});

const UserProvider = ({ children }) => {
  const { showDialog } = useDialog();
  const { sendUserToAnalytics } = useLGPDConsents();
  const {
    localOfferKeywords,
    localCouponKeywords,
    sendLocalCouponKeywordsToServer,
    sendLocalOfferKeywordsToServer,
  } = useLocalWishlist();
  const { sendPendingComments } = usePendingComments();
  const { changeThemeToStoredUserPreference, resetUserThemePreference } =
    useTheme();
  /**
   * Despite the majority of browsers already support BroadcastChannel API, we
   * have a lot of users that uses mobile safari <15.4 and because of that, to
   * avoid errors caused by a secondary feature, in these edge-cases we store
   * in a dummy object.
   */
  const channel = useSingleton(
    browserOnly(() =>
      'BroadcastChannel' in window
        ? new BroadcastChannel('login_cannel')
        : BROADCAST_CHANNEL_FALLBACK
    )
  );
  const [user, setUser] = useState({
    loadingLoggedInUser: true,
    LoggedInUser: null,
    isLoggedIn: false,
  });

  const resetUserPreferences = useCallback(async () => {
    const { clearLoginStorage } = await import('lib/user');

    resetUserThemePreference();
    clearLoginStorage();
    dispatch(USER_LOGOUT_EVENT);
  }, [resetUserThemePreference]);

  const saveLoginIfExists = useCallback(
    async ({ loggedInUser, token }) => {
      if (!loggedInUser && token) {
        await resetUserPreferences();
      }

      if (!loggedInUser) {
        return;
      }

      const { saveUserAsLegacyModeToLocalStorage } = await import(
        'lib/wishlist'
      );

      // Workaround for legacy compat mode
      saveUserAsLegacyModeToLocalStorage(loggedInUser);
      sendUserToAnalytics(loggedInUser);

      if (!getFromLocalStorage(LOCAL_STORAGE.IS_SUPIX_COLLECTED)) {
        await sendSupixEvent('/usa', { id: loggedInUser.userId });

        setLocalStorage(LOCAL_STORAGE.IS_SUPIX_COLLECTED, 'true');
      }
    },
    [resetUserPreferences, sendUserToAnalytics]
  );

  const checkLogin = useCallback(
    async ({ token }) => {
      const loggedInUser = await getLoggedInUser({
        skipOldApiWorkaround: process.env.NODE_ENV === 'development',
        token,
      });

      saveLoginIfExists({
        loggedInUser,
        token,
      });
      changeThemeToStoredUserPreference();
      setUser({
        loadingLoggedInUser: false,
        isLoggedIn: loggedInUser !== null,
        LoggedInUser: loggedInUser,
      });

      if (loggedInUser) {
        const { setUser: setSentryUser } = await import('@sentry/nextjs');

        setSentryUser({
          id: loggedInUser.userId,
          username: loggedInUser.userUsername,
        });
      }
    },
    [changeThemeToStoredUserPreference, saveLoginIfExists]
  );

  const login = useCallback(
    async ({ token }) => {
      try {
        const [
          { setCookie },
          { postCreateWebpush },
          { removeFromLocalStorage },
          { removeFromSessionStorage },
        ] = await Promise.all([
          import('cookies-next'),
          import('services/webpush'),
          import('lib/localStorage'),
          import('lib/sessionStorage'),
        ]);

        setLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN, token);
        removeFromLocalStorage(LOCAL_STORAGE.SHOWED_WELCOME_NOTIFICATION);
        setCookie(COOKIES.IS_USER_LOGGED, true, {
          maxAge: TOKEN_EXPIRATION_TIME,
          sameSite: true,
        });
        removeFromSessionStorage('utm_campaign');

        setUser((prevState) => ({
          ...prevState,
          loadingLoggedInUser: false,
        }));

        if (localOfferKeywords.length > 0) {
          await sendLocalOfferKeywordsToServer();
        }

        if (localCouponKeywords.length > 0) {
          await sendLocalCouponKeywordsToServer();
        }

        if (getFromLocalStorage(LOCAL_STORAGE.WEBPUSH)) {
          await postCreateWebpush({ userAuthToken: token });
        }

        dispatch(USER_LOGIN_EVENT);
        channel.postMessage('login');

        window.location.reload();
      } catch {
        await resetUserPreferences();
        setUser((lastUserState) => ({
          ...lastUserState,
          loadingLoggedInUser: false,
        }));
      }
    },
    [channel]
  );

  const logout = useCallback(async () => {
    await sendSupixEvent('/clr');
    await resetUserPreferences();

    setUser((lastUserState) => ({
      ...lastUserState,
      LoggedInUser: null,
      isLoggedIn: false,
    }));

    channel.postMessage('logout');

    // Workaround for legacy compat logout
    if (isDev()) {
      window.location.reload();
      return;
    }

    window.location.assign(
      `https://${process.env.NEXT_PUBLIC_SUBDOMAIN}.promobit.com.br/User/logout`
    );
  }, [channel]);

  const runCallbackIfLoggedIn = useCallback(
    (callback, ...args) => {
      if (user.loadingLoggedInUser) {
        return;
      }

      if (!user.isLoggedIn) {
        showDialog(LoginDialog);
        return;
      }

      callback(...args);
    },
    [user]
  );

  useEffect(() => {
    checkLogin({ token: getFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN) });
  }, []);

  useEffect(() => {
    if (user.loadingLoggedInUser || !user.isLoggedIn) {
      return;
    }

    /**
     * In some unknown case where the user got logged in and we try to send
     * the comments too fast right after that the server returns a 500 error.
     * To mitigate this on our side, we wait a generous
     * `TIMEOUT_TO_CREATE_RECOVERED_COMMENTS` timeout to happen before sending
     * them.
     */
    const TIMEOUT_TO_CREATE_RECOVERED_COMMENTS = 3000; // 3 seconds

    setTimeout(sendPendingComments, TIMEOUT_TO_CREATE_RECOVERED_COMMENTS);
  }, [user.isLoggedIn, user.loadingLoggedInUser]);

  useEffect(() => {
    const onMessage = (event) => {
      switch (event.data) {
        case 'login':
          checkLogin({
            token: getFromLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN),
          });
          window.location.reload();
          break;
        case 'logout':
          window.location.reload();
          break;
      }
    };

    channel.addEventListener('message', onMessage);

    return () => {
      channel.removeEventListener('message', onMessage);
    };
  }, [channel]);

  useEffect(() => {
    if (user.loadingLoggedInUser) {
      return;
    }

    const requestSubscription = async () => {
      const isNotificationsSubscriptionOnboardingHappened =
        !!getFromLocalStorage(LOCAL_STORAGE.SHOWED_WELCOME_NOTIFICATION);

      if (
        isNotificationsSubscriptionOnboardingHappened ||
        !('Notification' in window) ||
        isDenied(Notification.permission)
      ) {
        return;
      }

      const { requestNotificationSubscription, isNotificationsSupported } =
        await import('lib/notificationsSubscription');

      const isSupported = await isNotificationsSupported();

      if (!isSupported) {
        return;
      }

      return requestNotificationSubscription();
    };

    return requestSubscription();
  }, [user]);

  const value = useMemo(
    () => ({
      ...user,
      login,
      logout,
      runCallbackIfLoggedIn,
    }),
    [user, login, logout, runCallbackIfLoggedIn]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
